import * as React from 'react'; ;

import { Nav } from '#components/nav';
import { NavMain } from '#components/nav-main';
import { NotFound } from '#slices/not-found';
import { SEO } from '#components/seo';
import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import { getObjectData } from '#utils/prismicParseHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import { linkResolver } from '#utils/linkResolver';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import NewsTemplate from '#layout/news-page';
import PageTemplate from '#layout/page';
import PolicyTemplate from '#layout/policy-page';
import Wrapper from '#layout/wrapper';

const query = graphql`
	query {
		prismic404Page {
			id
			data {
				seo_description {
					text
				}
			  	seo_title {
					text
			  	}
			  	subtitle {
					text
			  	}
			  	title {
					text
			  	}
			}
		}
	}
`;

const NotFoundPage = () => {
	const staticData = useStaticQuery(query);
	const { data: { prismic404Page: { data } } } = useMergePrismicPreviewData(staticData);

	const title = getObjectData(data, 'title.text');
	const subtitle = getObjectData(data, 'subtitle.text');

	return (
		<Wrapper>
			<NavMain />
			<Nav mode={Nav.MODE.DARK} />
			<NotFound title={title} subtitle={subtitle} category='404' />
		</Wrapper>
	);
};


export const Head = (props) => {
	const { prismic404Page, location } = props.data;
	const pathname = getObjectData(location, 'pathname');

	const title = 'Page Not Found';
	const seoDescription = getObjectData(prismic404Page, 'data.seo_description.text');
	const seoTitle = getObjectData(prismic404Page, 'data.seo_title.text');

	return (
		<SEO
			title={title}
			seoTitle={seoTitle}
			seoDesciption={seoDescription}
			image={null}
			url={pathname}
			type={SEO.TYPE.WEBSITE}
		/>
	);
};

export default withPrismicUnpublishedPreview(NotFoundPage,
	[
		{
			repositoryName: 'cometa-website',
			linkResolver,
			componentResolver: componentResolverFromMap({
				page: PageTemplate,
				newsPage: NewsTemplate,
				policyPage: PolicyTemplate
			})
		}
	]
);
