import React from 'react';

import { Button } from '#components/button';
import { Pill } from '#components/pill';
import { Slice, SliceColumn, SliceContainer } from '#slices/slice';
import { Typography } from '#components/typography';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

export const NotFound = ({ className, title, subtitle, category }) => {
	const containerClasses = classNames(
		styles.cmtSNotFound,
		className
	);

	return (
        <Slice className={containerClasses}>
            <SliceContainer>
                <SliceColumn col className={styles.cmtSNotFound__sliceColumn}>
                    <div className={styles.cmtSNotFound__pill}>
                        <Pill variant={Pill.VARIANT.PRIMARY}>{category}</Pill>
                    </div>
                    <div className={styles.cmtSNotFound__titleContainer}>
                        <h1 className={styles.cmtSNotFound__title}>{title}</h1>
                    </div>
                    <div className={styles.cmtSNotFound__subtext}>
                        <Typography variant={Typography.VARIANT.B2}>
                            {subtitle}
                        </Typography>
                    </div>
                    <Button className={styles.cmtSNotFound__button} contained to='/'>Visit our homepage</Button>
                </SliceColumn>
            </SliceContainer>
        </Slice>
	);
};

export default NotFound;